import { useCallback, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { LiaGlobeAmericasSolid } from 'react-icons/lia'
import { RiStore2Fill } from 'react-icons/ri'

import BadgeIsValid from '~/components/BadgeIsValid'
import Loading from '~/components/Loading'
import Panel from '~/components/Panel'
import { Badge } from '~/components/UI/Badge'
import { Label, Radio } from '~/components/unform'
import { useAlert } from '~/hooks/useAlert'
import { adTypesOptions } from '~/modules/retailMedia/constants/adTypesOptions'
import { useAdTypes } from '~/modules/retailMedia/hooks/useAdTypes'

import { useCampaignForm } from '../hooks/useCampaignForm'
import * as S from '../styles'

const CampaignType = () => {
  const { t } = useTranslation()

  const { selectedType: initialType, handleType: onChange } = useCampaignForm()
  const { isPending, data: adTypes = [], error } = useAdTypes()

  const [AlertError] = useAlert(error)

  const [selectedValue, setSelectedValue] = useState<CampaignAdType | null>(
    initialType
  )

  const optionsAdTypes = useMemo(
    () => adTypesOptions.filter(item => adTypes?.includes(item.value)),
    [adTypes]
  )

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value as CampaignAdType

      setSelectedValue(value)
      onChange(value)
    },
    [onChange]
  )

  const hasOnSite = useMemo(
    () => optionsAdTypes.some(item => item.type === 'on_site'),
    [optionsAdTypes]
  )

  const hasInStore = useMemo(
    () => optionsAdTypes.some(item => item.type === 'in_store'),
    [optionsAdTypes]
  )

  return (
    <>
      <Panel
        title={t('rm:campaign.create.campaignType.selectedType.title')}
        description={
          selectedValue ? (
            <>
              {t('rm:campaign.create.campaignType.selectedType.message')}{' '}
              <Badge template="successSolid" size="tiny">
                {t(`rm:adTypes.${selectedValue}`)}
              </Badge>
            </>
          ) : (
            t('rm:campaign.create.campaignType.description')
          )
        }
        isCollapsible
        initialCollapsibleState={!selectedValue}
        iconLeft={<BadgeIsValid isValid={!!selectedValue} />}
      >
        <Loading status={isPending}>
          {t('rm:campaign.create.campaignType.loadingTypes')}
        </Loading>

        {hasOnSite && (
          <S.Group>
            <Label
              icon={<LiaGlobeAmericasSolid />}
              text="Onsite"
              helperText={t(
                'rm:campaign.create.campaignType.on_site.description'
              )}
            />

            <Radio
              options={optionsAdTypes.filter(item => item.type === 'on_site')}
              name="type"
              onChange={handleChange}
              required
            />
          </S.Group>
        )}

        {hasInStore && (
          <S.Group>
            <Label
              icon={<RiStore2Fill />}
              text="In-store"
              helperText={t(
                'rm:campaign.create.campaignType.in_store.description'
              )}
            />

            <Radio
              options={optionsAdTypes.filter(item => item.type === 'in_store')}
              name="type"
              onChange={handleChange}
              required
            />
          </S.Group>
        )}

        <AlertError />
      </Panel>
    </>
  )
}

export default CampaignType
